








































































































import { Component, Vue } from 'vue-property-decorator';
import MyNav from '../../components/Navbar.vue';
import axios from 'axios';
import slugify from 'slugify';

@Component({
  components: {
    MyNav
  }
})
export default class Home extends Vue {

  public list: any = null
  public name = ""
  public slug = "";
  public loading = false;
  public error = false;
  public edit: any = undefined;

  public async atualiza(){
    this.list = null
    this.name = '';
    this.slug = '';
    this.loading = true;
    document.title = `Admin - Lista de Clientes`; 
    const obj = await axios.get(process.env.VUE_APP_API+"client")
    .then((d) => {return {error: false, body : d.data.list}; })
    if (!obj.error){
      this.list = obj.body
    }
    this.loading = false;
  }

  public async created(){
    this.loading = true;
    await this.atualiza();    
  }


  public showLives(item: any){
    this.$router.push({ name: 'lives', params: { code: item._id } })
  }

  public editObj(item: any){
    this.edit = item;
    this.name = item.name;
    this.slug = item.slug;
  }

  public async deleteObj(item: any){
    if (confirm('Deseja excluir este cliente e as lives')){
      await axios.post(process.env.VUE_APP_API+"client/delete",{_id: item._id}).then((data) => data.data).catch(() => this.error = true);
      await this.atualiza();
    }
  }

  public async create(){
        this.error = false
        if (this.name!=='' && this.slug !== '')
        {
            this.loading = true;
            let resp = null;
            if (this.edit == null){
              resp = await axios.post(process.env.VUE_APP_API+"client/create",{
                  name: this.name,
                  slug: slugify(this.slug),
              }).then((data) => data.data).catch(() => this.error = true);                
              if (resp !== null && resp.uuid !== undefined){
                if (this.list == null) this.list = []
                this.list.push({
                  name: this.name,
                  slug: slugify(this.slug),
                  _id: resp.uuid
                })
              }              
            }
            if (this.edit !== null){
              resp = await axios.put(process.env.VUE_APP_API+`client/${this.edit['_id']}`,{
                name: this.name,
                slug: slugify(this.slug),
              }).then((data) => data.data).catch(() => this.error = true);              
              if (resp._id !== undefined){
                await this.atualiza();
              }
            }            
            this.loading = false;
        }
        return false;
    }
}
